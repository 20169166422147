import React, { useState, useCallback, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderSection from "./HeaderSection";
import { formatNumberWithCommas } from "../../utils/supportFunctions";

// Status configuration
const statusConfig = [
  {
    type: "published",
    label: "נסיעות שפורסמו",
    getCount: (data) => data.publishedTrips || 0,
    getAmount: (data) => data.publishedTripsAmount || 0,
    colorClass: "bg-primary",
    icon: "📢",
  },
  {
    type: "taken",
    label: "נסיעות שנלקחו",
    getCount: (data) => data.takenTrips || 0,
    getAmount: (data) => data.takenTripsAmount || 0,
    colorClass: "bg-warning",
    icon: "🚗",
  },
  {
    type: "inProgress",
    label: "נסיעות בתהליך",
    getCount: (data) => data.inProgress || 0,
    getAmount: (data) => data.inProgressTripsAmount || 0,
    colorClass: "bg-dark",
    icon: "🚗",
  },
  {
    type: "performed",
    label: "נסיעות שהושלמו",
    getCount: (data) => data.performedTrips || 0,
    getAmount: (data) => data.performedTripsAmount || 0,
    colorClass: "bg-success",
    icon: "✅",
  },
  {
    type: "canceled",
    label: "נסיעות שבוטלו",
    getCount: (data) => data.canceledTrips || 0,
    getAmount: (data) => data.canceledTripsAmount || 0,
    colorClass: "bg-secondary",
    icon: "❌",
  },
  {
    type: "open",
    label: "נסיעות פתוחות",
    getCount: (data) => data.openTrips || 0,
    getAmount: (data) => data.openTripsAmount || 0,
    colorClass: "bg-danger",
    icon: "⭕",
  },
];

// Status Card Component
const StatusCard = ({ status, analytics }) => {
  const count = status.getCount(analytics);
  const amount = status.getAmount(analytics);

  return (
    <div
      className={`${status.colorClass} bg-opacity-90 rounded p-4 text-white hover:bg-opacity-100 transition-all`}
    >
      <div className="d-flex align-items-center gap-2 mb-2">
        <span>{status.icon}</span>
        <h5 className="fs-5 fw-medium m-0">{status.label}</h5>
      </div>
      <p className="fs-2 fw-bold m-0">{count}</p>
      <p className="small opacity-90 mt-1">
        {formatNumberWithCommas(amount)} ₪
      </p>
    </div>
  );
};

// Main Component
const MiniDashboard = ({ onDateRangeChange, analytics }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Date range states
  const [dateRange, setDateRange] = useState(() => {
    const savedRange = localStorage.getItem("dashboardDateRange");
    return savedRange || "today";
  });

  const [showCustomRange, setShowCustomRange] = useState(false);

  const [startDate, setStartDate] = useState(() => {
    const savedStartDate = localStorage.getItem("dashboardStartDate");
    return savedStartDate ? new Date(savedStartDate) : new Date();
  });

  const [endDate, setEndDate] = useState(() => {
    const savedEndDate = localStorage.getItem("dashboardEndDate");
    return savedEndDate ? new Date(savedEndDate) : new Date();
  });

  const getDateRangeParams = useCallback(
    (selectedRange = dateRange) => {
      const now = new Date();
      const tomorrow = new Date(now);
      tomorrow.setDate(now.getDate() + 1);

      switch (selectedRange) {
        case "today":
          return { startDate: now, endDate: now };
        case "tomorrow":
          return { startDate: tomorrow, endDate: tomorrow };
        case "nextWeek":
          const nextWeekStart = new Date(now);
          const nextWeekEnd = new Date(nextWeekStart);
          nextWeekEnd.setDate(nextWeekStart.getDate() + 7);
          return { startDate: nextWeekStart, endDate: nextWeekEnd };
        case "week":
          const weekAgo = new Date(now);
          weekAgo.setDate(now.getDate() - 7);
          return { startDate: weekAgo, endDate: now };
        case "month":
          const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
          return { startDate: monthStart, endDate: now };
        case "fullMonth":
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          return { startDate: firstDay, endDate: lastDay };
        case "custom":
          if (startDate && endDate) {
            return { startDate, endDate };
          }
          return null;
        default:
          return { startDate: now, endDate: now };
      }
    },
    [dateRange, startDate, endDate]
  );

  const handleDateRangeChange = useCallback(
    async (newRange, newStartDate, newEndDate) => {
      let dates;
      if (newRange === "custom") {
        dates = { startDate: newStartDate, endDate: newEndDate };
      } else {
        dates = getDateRangeParams(newRange);
      }

      if (dates) {
        try {
          setIsLoading(true);

          // רכז את כל עדכוני ה-state ביחד
          await Promise.all([
            new Promise((resolve) => {
              setDateRange(newRange);
              setStartDate(dates.startDate);
              setEndDate(dates.endDate);
              setShowCustomRange(newRange === "custom");
              localStorage.setItem("dashboardDateRange", newRange);
              localStorage.setItem(
                "dashboardStartDate",
                dates.startDate.toISOString()
              );
              localStorage.setItem(
                "dashboardEndDate",
                dates.endDate.toISOString()
              );
              resolve();
            }),
          ]);

          // קרא לפונקציה החיצונית
          if (onDateRangeChange) {
            await onDateRangeChange(dates.startDate, dates.endDate);
          }
        } catch (error) {
          console.error("Error updating dashboard:", error);
          setError("שגיאה בעדכון נתוני לוח הבקרה");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [getDateRangeParams, onDateRangeChange]
  );

  // Persist date range settings
  useEffect(() => {
    localStorage.setItem("dashboardDateRange", dateRange);
    if (startDate) {
      localStorage.setItem("dashboardStartDate", startDate.toISOString());
    }
    if (endDate) {
      localStorage.setItem("dashboardEndDate", endDate.toISOString());
    }
  }, [dateRange, startDate, endDate]);

  return (
    <div className="bg-white rounded shadow p-4 mb-4">
      <HeaderSection
        dateRange={dateRange}
        setDateRange={(newRange) => handleDateRangeChange(newRange)}
        showCustomRange={showCustomRange}
        setShowCustomRange={setShowCustomRange}
        startDate={startDate}
        setStartDate={(date) => handleDateRangeChange("custom", date, endDate)}
        endDate={endDate}
        setEndDate={(date) => handleDateRangeChange("custom", startDate, date)}
      />

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">טוען...</span>
          </Spinner>
        </div>
      ) : error ? (
        <div className="text-center text-danger">{error}</div>
      ) : !analytics ? (
        <div className="text-center">לא נמצאו נתונים</div>
      ) : (
        <div className="row g-4">
          {statusConfig.map((status) => (
            <div
              key={status.type}
              className="col-12 col-sm-6 col-md-4 col-lg-2"
            >
              <StatusCard status={status} analytics={analytics} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MiniDashboard;
