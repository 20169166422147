import React from "react";
import { Button } from "react-bootstrap";
import { IoChevronBackOutline } from "react-icons/io5";

export default function BackButton() {
  return (
    <div>
      <Button
        className="border border-1  text-dark d-flex justify-content-between align-items-center  bg-warning"
        onClick={() => {
          window.history.back();
        }}
        style={{
          height: "50px",
          width: "100%",
        }}
      >
        <IoChevronBackOutline
          size={24}
          style={{
            transform: "scaleX(-1)",
          }}
        />
        <span className="font-weight-bold">חזור</span>
      </Button>
    </div>
  );
}
