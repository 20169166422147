import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { fetchData } from "../../../../utils/fetchData";
import AreYouSureModal from "../../../../components/AreYouSureModal/AreYouSureModal";
import { CreateDriverModal } from "../../CreateDriverModal";
import { useToast } from "../../../../context/ToastContext";
import { usePermissions } from "../../../../context/usePermissions";

const ClientDriverTab = ({ client = {}, context }) => {
  const { hasPermission } = usePermissions();
  const { showSuccessToast, showErrorToast } = useToast();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [driverToRemove, setDriverToRemove] = useState(null);

  useEffect(() => {
    if (client?._id) {
      fetchDrivers();
    }
  }, [client?._id]);

  // Fetch the list of drivers for the client
  const fetchDrivers = async () => {
    setLoading(true);
    try {
      const token = getToken();

      let request;
      console.log("context", context);
      if (context === "branch") {
        request = `branches/${client._id}/drivers`;
      } else {
        request = `clients/${client._id}/drivers`;
      }

      const driversResponse = await fetchData({ request }, "GET", token);

      setDrivers(driversResponse.drivers);
    } catch (error) {
      console.error("Error fetching drivers:", error);
      showErrorToast("שגיאה בטעינת הסדרנים");
    } finally {
      setLoading(false);
    }
  };

  const getToken = () => {
    const userData = localStorage.getItem("userData");
    if (!userData) throw new Error("User is not authenticated");
    return JSON.parse(userData).token;
  };

  const handleOpenModal = (driver = null) => {
    setSelectedDriver(driver);
    setIsModalOpen(true);
  };

  const handleRemoveDriver = async () => {
    try {
      const token = getToken();

      await fetchData(
        { request: `driver/${driverToRemove._id}` },
        "DELETE",
        token
      );

      setDrivers((prev) =>
        prev.filter((driver) => driver._id !== driverToRemove._id)
      );

      showSuccessToast("מנהל ההתקן הוסר בהצלחה");
    } catch (error) {
      console.error("Error removing driver:", error);
      showErrorToast("לא ניתן להסיר רכב");
    } finally {
      setIsAreYouSureOpen(false);
    }
  };

  const handleShowAreYouSureModal = (driver) => {
    setDriverToRemove(driver);
    setIsAreYouSureOpen(true);
  };

  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setDriverToRemove(null);
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex w-100 justify-content-end">
          {hasPermission("clients.organizers", "write") && (
            <Button className="table-btn" onClick={() => handleOpenModal()}>
              הוסף נהג
            </Button>
          )}
        </div>
      </div>

      {loading ? (
        <p>טוען...</p>
      ) : drivers?.length > 0 ? (
        drivers?.map((driver, index) => (
          <div key={driver._id}>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>
                  <strong className="font-weight-bold">שם הנהג:</strong>{" "}
                  {driver.firstName}
                  {/* {organizer.isActive && (
                    <span
                      style={{
                        color: "green",
                        fontSize: "24px",
                        marginLeft: "10px",
                      }}
                    >
                      ●
                    </span>
                  )} */}
                </p>
                <p>
                  <strong className="font-weight-bold">קוד מפעיל:</strong>{" "}
                  {driver.driverCode}
                </p>
                <p>
                  <strong className="font-weight-bold">מספר נייד:</strong>{" "}
                  {driver.phoneNumber}
                </p>
                {/* <p>
                  <strong className="font-weight-bold">פעילות אחרונה:</strong>{" "}
                  {driver.lastActive
                    ? new Date(driver.lastActive).toLocaleString("he-IL")
                    : "לא נרשמה פעילות"}
                </p> */}
              </div>
              <div className="d-flex gap-2">
                {hasPermission("clients.organizers", "edit") && (
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleOpenModal(driver)}
                  >
                    <BsPencilSquare /> עריכה
                  </Button>
                )}
                {hasPermission("clients.organizers", "delete") && (
                  <Button
                    variant="outline-danger"
                    onClick={() => handleShowAreYouSureModal(driver)}
                  >
                    <BsTrash /> מחיקה
                  </Button>
                )}
              </div>
            </div>
            {index < drivers?.length - 1 && <hr />}
          </div>
        ))
      ) : (
        <p>אין מנהלי התקנים עבור לקוח זה. לחץ על "הוסף מנהל התקן" כדי להוסיף</p>
      )}

      {/* Modals */}
      <CreateDriverModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        fetchDrivers={fetchDrivers}
        driver={selectedDriver}
        setDriver={setSelectedDriver}
        client={client}
      />

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemoveDriver}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך להסיר את מנהל ההתקן? ${driverToRemove?.firstName}?`}
      />
    </div>
  );
};

export default ClientDriverTab;
