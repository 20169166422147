import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../context/ToastContext";
import { fetchData } from "../../utils/fetchData";
import { BsInfoCircle, BsEye, BsEyeSlash } from "react-icons/bs";
import { PERMISSIONS } from "../../config/permissions";
import { defaultPermissions } from "../../config/permissions";
import BackButton from "../../components/BackButton/BackButton";

const EditAdminPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const [adminData, setAdminData] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);

  // Form states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  // Validation states
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  useEffect(() => {
    fetchAdminData();
  }, [id]);

  const fetchAdminData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userData")).token;
      const response = await fetchData(
        { request: `admins/${id}` },
        "GET",
        token
      );

      if (response.success) {
        const admin = response.admin;
        setAdminData(admin);
        setPermissions(admin.permissions || {});
        setFirstName(admin.firstName || "");
        setLastName(admin.lastName || "");
        setEmail(admin.email || "");
        setPhoneNumber(admin.phoneNumber || "");
      }
    } catch (error) {
      showErrorToast("שגיאה בטעינת נתוני המשתמש");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!firstName) {
      setFirstNameError(true);
      isValid = false;
    }
    if (!lastName) {
      setLastNameError(true);
      isValid = false;
    }
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      isValid = false;
    }
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      setPhoneNumberError(true);
      isValid = false;
    }
    if (showPasswordFields) {
      if (password && password.length < 6) {
        setPasswordError(true);
        isValid = false;
      }
      if (password && password !== confirmPassword) {
        setConfirmPasswordError(true);
        isValid = false;
      }
    }

    return isValid;
  };

  const handlePermissionChange = (section, permission, checked) => {
    setPermissions((prev) => {
      const newPermissions = { ...prev };
      if (!newPermissions[section]) {
        newPermissions[section] = [];
      }

      if (checked) {
        newPermissions[section] = [
          ...new Set([...newPermissions[section], permission]),
        ];
      } else {
        newPermissions[section] = newPermissions[section].filter(
          (p) => p !== permission
        );
      }

      if (newPermissions[section].length === 0) {
        delete newPermissions[section];
      }

      return newPermissions;
    });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const token = JSON.parse(localStorage.getItem("userData")).token;
      const updateData = {
        request: `admins/${id}`,
        firstName,
        lastName,
        email,
        phoneNumber,
        permissions,
        ...(showPasswordFields && password ? { password } : {}),
      };

      const response = await fetchData(updateData, "PUT", token);

      if (response.success) {
        showSuccessToast("המשתמש עודכן בהצלחה");
        navigate("/dashboard/admins");
      }
    } catch (error) {
      showErrorToast("שגיאה בעדכון המשתמש");
    }
  };

  const getGroupLabel = (mainSection) => {
    const labels = {
      dashboard: "לוח הבקרה",
      clients: "לקוחות",
      admins: "משתמשי מערכת",
      trips: "נסיעות",
      settings: "הגדרות",
      activities: "פעילות",
    };
    return labels[mainSection] || mainSection;
  };

  const groupedPermissions = useMemo(() => {
    const mainPages = defaultPermissions
      .filter((p) => p.group === "core")
      .map((p) => ({
        key: p.key,
        label: p.label,
      }));
    const result = {};

    mainPages.forEach((mainPage) => {
      result[mainPage.key] = {
        label: getGroupLabel(mainPage.key),
        items: [
          {
            key: mainPage.key,
            label: `צפייה בעמוד ${mainPage.label}`,
            permissions: ["read"],
            order: -1,
          },
        ],
      };
    });

    defaultPermissions
      .filter((p) => p.key.includes("."))
      .forEach((permission) => {
        const mainSection = permission.key.split(".")[0];
        if (result[mainSection]) {
          result[mainSection].items.push(permission);
        }
      });
    Object.values(result).forEach((group) => {
      group.items.sort((a, b) => a.order - b.order);
    });

    return result;
  }, []);

  if (loading) {
    return <div className="text-center mt-5">טוען...</div>;
  }

  return (
    <Container fluid className="p-4">
      <div className="w-10 d-flex mb-4">
        <BackButton />
      </div>
      <Card className="mb-4">
        <Card.Header className="bg-primary text-white">
          <h4 className="mb-0">
            עריכת משתמש - {adminData?.firstName} {adminData?.lastName}
          </h4>
        </Card.Header>
        <Card.Body>
          <Tabs defaultActiveKey="details" className="mb-4">
            <Tab eventKey="details" title="פרטים אישיים">
              <div className="p-3">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>שם פרטי</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          setFirstNameError(false);
                        }}
                        isInvalid={firstNameError}
                      />
                      <Form.Control.Feedback type="invalid">
                        שדה חובה
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>שם משפחה</Form.Label>
                      <Form.Control
                        type="text"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                          setLastNameError(false);
                        }}
                        isInvalid={lastNameError}
                      />
                      <Form.Control.Feedback type="invalid">
                        שדה חובה
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>אימייל</Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailError(false);
                        }}
                        isInvalid={emailError}
                      />
                      <Form.Control.Feedback type="invalid">
                        אימייל לא תקין
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>טלפון</Form.Label>
                      <Form.Control
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          setPhoneNumberError(false);
                        }}
                        isInvalid={phoneNumberError}
                      />
                      <Form.Control.Feedback type="invalid">
                        מספר טלפון לא תקין
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mb-3">
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={() => setShowPasswordFields(!showPasswordFields)}
                  >
                    {showPasswordFields ? "בטל שינוי סיסמה" : "שנה סיסמה"}
                  </Button>
                </div>

                {showPasswordFields && (
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>סיסמה חדשה</Form.Label>
                        <div className="position-relative d-flex gap-2">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordError(false);
                            }}
                            isInvalid={passwordError}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {showPassword ? "הסתר סיסמה" : "הצג סיסמה"}
                          </Button>
                          <Form.Control.Feedback type="invalid">
                            הסיסמה חייבת להכיל לפחות 6 תווים
                          </Form.Control.Feedback>
                        </div>

                        <Form.Control.Feedback type="invalid">
                          הסיסמה חייבת להכיל לפחות 6 תווים
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>אימות סיסמה</Form.Label>
                        <div className="position-relative d-flex gap-2">
                          <Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              setConfirmPasswordError(false);
                            }}
                            isInvalid={confirmPasswordError}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {showConfirmPassword ? "הסתר סיסמה" : "הצג סיסמה"}
                          </Button>
                          <Form.Control.Feedback type="invalid">
                            הסיסמאות אינן תואמות
                          </Form.Control.Feedback>
                        </div>

                        <Form.Control.Feedback type="invalid">
                          הסיסמאות אינן תואמות
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            </Tab>

            <Tab eventKey="permissions" title="הרשאות">
              <div className="p-3">
                {Object.entries(groupedPermissions).map(
                  ([group, { items, label }]) => (
                    <Card key={group} className="mb-4">
                      <Card.Header className="bg-primary text-white p-2 rounded">
                        <h5 className="mb-0">{label}</h5>
                      </Card.Header>
                      <Card.Body>
                        <Row className="g-4">
                          {items.map((permission) => (
                            <Col md={6} key={permission.key} className="mb-4">
                              <Card className="h-100">
                                <Card.Header className="bg-light">
                                  <h6 className="mb-0">{permission.label}</h6>
                                </Card.Header>
                                <Card.Body>
                                  {permission.permissions.map((perm) => (
                                    <div
                                      key={`${permission.key}-${perm}`}
                                      className="mb-3"
                                    >
                                      <OverlayTrigger
                                        placement="right"
                                        overlay={
                                          <Tooltip>{permission.label}</Tooltip>
                                        }
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          id={`${permission.key}-${perm}`}
                                          className="d-flex align-items-center gap-2"
                                          label={
                                            <div className="d-flex align-items-center gap-2">
                                              <span>
                                                {perm === PERMISSIONS.READ &&
                                                  "צפייה"}
                                                {perm === PERMISSIONS.WRITE &&
                                                  "עריכה"}
                                                {perm === PERMISSIONS.EDIT &&
                                                  "עריכה"}
                                                {perm === PERMISSIONS.DELETE &&
                                                  "מחיקה"}
                                              </span>
                                              <BsInfoCircle className="text-muted" />
                                            </div>
                                          }
                                          checked={
                                            permissions[
                                              permission.key
                                            ]?.includes(perm) || false
                                          }
                                          onChange={(e) =>
                                            handlePermissionChange(
                                              permission.key,
                                              perm,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  ))}
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                )}
              </div>
            </Tab>
          </Tabs>

          <div className="d-flex justify-content-between mt-4">
            <Button
              variant="secondary"
              onClick={() => navigate("/dashboard/admins")}
            >
              ביטול
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              שמור שינויים
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditAdminPage;
