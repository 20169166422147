import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import { useToast } from "../../context/ToastContext";

export const CreateDriverModal = ({
  isOpen,
  setIsOpen,
  fetchDrivers,
  driver,
  setDriver,
  client,
}) => {
  const { showSuccessToast, showErrorToast } = useToast();
  const [firstName, setFirstName] = useState("");
  const [driverCode, setDriverCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [DriverCodeError, setDriverCodeError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  useEffect(() => {
    if (driver) {
      setFirstName(driver.firstName);
      setDriverCode(driver.driverCode);
      setPhoneNumber(driver.phoneNumber);
    } else {
      setFirstName("");
      setDriverCode("");
      setPhoneNumber("");
    }
  }, [driver]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (driver) {
      await updateDriver();
    } else {
      await addNewDriver();
    }
  };

  const validateForm = () => {
    let valid = true;

    if (!firstName) {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }

    if (!driverCode) {
      setDriverCodeError(true);
      valid = false;
    } else {
      setDriverCodeError(false);
    }

    // if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
    //   setPhoneNumberError(true);
    //   valid = false;
    // } else {
    //   setPhoneNumberError(false);
    // }

    return valid;
  };

  const addNewDriver = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestData = client.parentClient
        ? {
            request: "driver/register",
            firstName,
            driverCode,
            phoneNumber,
            branchId: client._id,
          }
        : {
            request: "driver/register",
            firstName,
            driverCode,
            phoneNumber,
            clientId: client._id,
          };

      const response = await fetchData(requestData, "POST", token);

      if (response.success) {
        showSuccessToast("מנהל ההתקן נוסף בהצלחה");
        setIsOpen(false);
        fetchDrivers();
        resetFormFields();
      } else {
        throw new Error(response.message || "שגיאה ביצירת נהג");
      }
    } catch (err) {
      console.error("Error adding new driver:", err.message);
      showErrorToast(
        err.response?.data?.message || err.message || "שגיאה ביצירת נהג"
      );
    }
  };

  const updateDriver = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: `driver/${driver._id}`,

        firstName,
        driverCode,
        phoneNumber,
        parentClient: client.parentClient
          ? client.parentClient._id
          : client._id,
        branch: client.parentClient ? client._id : null,
      };

      await fetchData(requestObject, "PUT", token);
      showSuccessToast("מנהל ההתקן נערך בהצלחה");
      fetchDrivers();
      closeModal();
    } catch (err) {
      console.error(err.message);
      showErrorToast("לא ניתן לערוך את הנהג");
      fetchDrivers();
      closeModal();
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    resetFormFields();
  };

  const resetFormFields = () => {
    setFirstName("");
    setDriverCode("");
    setPhoneNumber("");
    setFirstNameError(false);
    setDriverCodeError(false);
    setPhoneNumberError(false);
    setDriver(null);
  };

  return (
    <Modal show={isOpen} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>{driver ? "עריכת נהג" : "צור נהג חדש"}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="create_organizer_modal--wrapper">
            <Form.Group className="mb-3" controlId="firstName">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  שם מלא
                </span>
              </div>
              <Form.Control
                type="text"
                value={firstName}
                placeholder="הזן את השם המלא"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstNameError(false);
                }}
                required
                className={firstNameError ? "input-error" : "input-regular"}
              />
              {firstNameError && (
                <span className="text-danger text-sm">שדה חובה</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="driverCode">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  קוד מפעיל{" "}
                </span>
              </div>
              <Form.Control
                type="text"
                value={driverCode}
                placeholder="הזן את קוד הנהג"
                onChange={(e) => {
                  setDriverCode(e.target.value);
                  setDriverCodeError(false);
                }}
                required
                className={DriverCodeError ? "input-error" : "input-regular"}
              />
              {DriverCodeError && (
                <span className="text-danger text-sm">שדה חובה</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="phoneNumber">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  טלפון
                </span>
              </div>
              <Form.Control
                type="text"
                value={phoneNumber}
                placeholder="הכנס מספר טלפון"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setPhoneNumberError(false);
                }}
                // required
                maxLength={10}
                className={phoneNumberError ? "input-error" : "input-regular"}
              />
              {phoneNumberError && (
                <span className="text-danger text-sm">
                  מספר טלפון צריך להיות באורך 10 ספרות
                </span>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            סגירה
          </Button>
          <Button variant="success" type="submit">
            המשך
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
