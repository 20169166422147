import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, Pagination, Form } from "react-bootstrap";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { useLoading } from "../../../context/LoadingContext";
import { useToast } from "../../../context/ToastContext";
import { fetchData } from "../../../utils/fetchData";
import AreYouSureModal from "../../../components/AreYouSureModal/AreYouSureModal";
import { getClientTypeText } from "../../../utils/supportFunctions";
// import "";
import "./vehiclesTable.scss";
import { CLIENTS_SECTIONS } from "../../../config/permissions";

const VehiclesTable = ({
  setIsOpen,
  fetchVehicles,
  setVehicle,
  vehicle,
  vehicles,
  totalPages,
  initialState,
  updateUrlParams,
  cities = [],
  states = [],
  hasPermission,
}) => {
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("userData")).token;

  const [activeOrganizers, setActiveOrganizers] = useState({});
  const [activeThresholdMinutes, setActiveThresholdMinutes] = useState(null);
  const [searchTerm, setSearchTerm] = useState(initialState.searchTerm || "");
  const [selectedCity, setSelectedCity] = useState(
    initialState.selectedCity || ""
  );
  const [sortConfig, setSortConfig] = useState({
    field: initialState.sortField || "",
    order: initialState.sortOrder || "",
  });
  const [pendingSearchTerm, setPendingSearchTerm] = useState(searchTerm);
  const [pendingSelectedCity, setPendingSelectedCity] = useState(selectedCity);
  const [currentPage, setCurrentPage] = useState(initialState.currentPage || 1);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [vehiclerToRemove, setVehicleRToRemove] = useState(null);
  const [vehiclers, setVehicleRs] = useState([]);
  const [selectedVehicleR, setSelectedVehicleR] = useState(null);
  const { showSuccessToast, showErrorToast } = useToast();
  const [selectedState, setSelectedState] = useState(
    initialState.selectedState || ""
  );
  const [pendingSelectedState, setPendingSelectedState] =
    useState(selectedState);

  useEffect(() => {
    const loadData = async () => {
      showLoading();
      try {
        await Promise.all([
          fetchActiveOrganizers(),
          fetchActiveThresholdMinutes(),
        ]);
      } finally {
        hideLoading();
      }
    };

    loadData();
  }, []);

  const fetchActiveOrganizers = async () => {
    try {
      const response = await fetchData(
        { request: `organizers/active-organizers` },
        "GET",
        token
      );
      setActiveOrganizers(response);
    } catch (error) {
      console.error("Error fetching active organizers:", error);
      showErrorToast("שגיאה בטעינת סדרנים פעילים");
    }
  };

  const fetchActiveThresholdMinutes = async () => {
    try {
      const response = await fetchData(
        { request: `config/active-users-threshold` },
        "GET",
        token
      );
      setActiveThresholdMinutes(response.activeUsersDisplayThresholdMinutes);
    } catch (error) {
      console.error("Error fetching active users threshold:", error);
      showErrorToast("שגיאה בטעינת הגדרות זמן פעילות");
    }
  };

  // Handle column sorting
  const handleSort = (field) => {
    let order = "asc";
    if (sortConfig.field === field) {
      if (sortConfig.order === "asc") order = "desc";
      else if (sortConfig.order === "desc") {
        field = "";
        order = "";
      }
    }

    setSortConfig({ field, order });
    updateUrlParams(
      {
        sortField: field,
        sortOrder: order,
        page: 1,
      },
      true
    );
  };

  useEffect(() => {
    updateUrlParams(
      {
        search: searchTerm || null, // אם ריק, שלח null
        city: selectedCity || null, // אם ריק, שלח null
        state: selectedState || null, // אם ריק, שלח null
        page: 1,
      },
      true
    );
  }, [searchTerm, selectedCity, selectedState]);

  // Add this useEffect to handle URL updates when search states change
  useEffect(() => {
    if (
      searchTerm !== initialState.searchTerm ||
      selectedCity !== initialState.selectedCity ||
      selectedState !== initialState.selectedState
    ) {
      updateUrlParams(
        {
          search: searchTerm,
          city: selectedCity,
          state: selectedState,
          page: 1,
        },
        true
      );
    }
  }, [searchTerm, selectedCity, selectedState]);

  // Add sort indicators to column headers
  const getSortIcon = (field) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.order === "asc" ? " ▲" : " ▼";
  };

  const PaginationComponent = () => {
    const maxPages = 5;
    const itemsPerPage = 25;

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      updateUrlParams({ page: pageNumber }, true);
    };

    // First page
    const items = [
      <Pagination.First
        key="first"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      />,
      <Pagination.Prev
        key="prev"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />,
    ];

    // Calculate start and end pages
    const startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    const endPage = Math.min(totalPages, startPage + maxPages - 1);

    // Add page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Next and Last page
    items.push(
      <Pagination.Next
        key="next"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      />,
      <Pagination.Last
        key="last"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(totalPages)}
      />
    );

    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Pagination>{items}</Pagination>
          <div className="mx-3 d-flex justify-content-center align-items-center mt-2">
            <span className="text-muted">
              מציג {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, totalPages * itemsPerPage)}{" "}
              מתוך {totalPages * itemsPerPage} תוצאות
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => (
    <div className="table-container">
      {vehicles.length > 0 ? (
        <div className="table-responsive">
          <Table responsive className="table-hover">
            <thead className="thead-light sticky-header">
              <tr>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("llsNumber")}
                >
                  שם הרכב {getSortIcon("llsNumber")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("clientType")}
                >
                  פעולה {getSortIcon("clientType")}
                </th>
              </tr>
            </thead>
            <tbody>
              {vehicles.map((vehicle, index) => {
                return (
                  <tr key={vehicle._id} className="table-row hover-link">
                    <td>{vehicle.vehicleName}</td>

                    <td>
                      {hasPermission("clients.organizers", "edit") && (
                        <Button
                          style={{ margin: "5px" }}
                          variant="outline-secondary"
                          onClick={() => handleEditModal(vehicle)}
                        >
                          <BsPencilSquare /> עריכה
                        </Button>
                      )}
                      {hasPermission("clients.organizers", "delete") && (
                        <Button
                          style={{ margin: "5px" }}
                          variant="outline-danger"
                          onClick={() => handleShowAreYouSureModal(vehicle)}
                        >
                          <BsTrash /> מחיקה
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="no-data-message">אין נתונים להצגה</div>
      )}
    </div>
  );

  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setVehicleRToRemove(null);
  };

  const handleShowAreYouSureModal = (vehicler) => {
    setVehicleRToRemove(vehicler);
    setIsAreYouSureOpen(true);
  };

  const handleEditModal = (vehicler) => {
    setVehicle(vehicler);
    setIsOpen(true);
  };

  const getToken = () => {
    const userData = localStorage.getItem("userData");
    if (!userData) throw new Error("User is not authenticated");
    return JSON.parse(userData).token;
  };

  const handleRemoveVehicleR = async () => {
    try {
      const token = getToken();

      await fetchData(
        { request: `vehicle/${vehiclerToRemove._id}` },
        "DELETE",
        token
      );

      setVehicleRs((prev) =>
        prev.filter((vehicler) => vehicler._id !== vehiclerToRemove._id)
      );

      showSuccessToast("מנהל ההתקן הוסר בהצלחה");
      fetchVehicles();
    } catch (error) {
      console.error("Error removing vehicler:", error);
      showErrorToast("לא ניתן להסיר רכב");
    } finally {
      setIsAreYouSureOpen(false);
    }
  };

  return (
    <Fragment>
      <div className="custom-table-container">
        <div className="table-header d-flex flex-column gap-4">
          <div className="d-flex flex-row justify-content-between">
            <div></div>

            {hasPermission("clients.addingClients", "write") && (
              <Button
                className="table-btn"
                variant="primary"
                onClick={() => setIsOpen(true)}
              >
                צור רכב חדש
              </Button>
            )}
          </div>
        </div>

        {renderTable()}

        {vehicles.length > 0 && totalPages > 1 && <PaginationComponent />}
      </div>
      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemoveVehicleR}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך להסיר את הרכב ${vehiclerToRemove?.vehicleName}?`}
      />
    </Fragment>
  );
};

export default VehiclesTable;
