import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { fetchData } from "../../../../utils/fetchData";
import AreYouSureModal from "../../../../components/AreYouSureModal/AreYouSureModal";
import { CreateDriverModal } from "../../CreateVehicleAssignModal";
import { useToast } from "../../../../context/ToastContext";
import { usePermissions } from "../../../../context/usePermissions";

const ClientDriverTab = ({ client = {}, context }) => {
  const { hasPermission } = usePermissions();
  const { showSuccessToast, showErrorToast } = useToast();
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [vehicleToRemove, setVehicleToRemove] = useState(null);

  useEffect(() => {
    if (client?._id) {
      fetchDrivers();
    }
  }, [client?._id]);

  // Fetch the list of drivers for the client
  const fetchDrivers = async () => {
    setLoading(true);
    try {
      const token = getToken();

      let request;
      console.log(client);
      if (context === "branch") {
        request = `clients/${client.parentClient._id}/vehicles`;
      } else {
        request = `clients/${client._id}/vehicles`;
      }

      const driversResponse = await fetchData({ request }, "GET", token);
      setVehicles(driversResponse.vehicles);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      showErrorToast("שגיאה בטעינת הסדרנים");
    } finally {
      setLoading(false);
    }
  };

  const getToken = () => {
    const userData = localStorage.getItem("userData");
    if (!userData) throw new Error("User is not authenticated");
    return JSON.parse(userData).token;
  };

  const handleOpenModal = (driver = null) => {
    setSelectedDriver(driver);
    setIsModalOpen(true);
  };

  const handleRemoveVehicle = async () => {
    try {
      const token = getToken();
      let branchId = null;
      let clientId = client.parentClient._id;
      if (context == "branch") {
        branchId = client._id;
      }
      const requestBody = {
        clientId: clientId,
        vehicleId: vehicleToRemove.id,
        branchId: branchId,
      };
      const response = await fetchData(
        { request: "vehicle/deleteVehicle", body: requestBody },
        "POST",
        token
      );

      if (response.success) {
        setVehicles((prev) =>
          prev.filter((vehicle) => vehicle.id !== vehicleToRemove.id)
        );

        showSuccessToast(response.message || "הוִיהָל הוסר בהצלחה");
      } else {
        showErrorToast(response.message || "לא ניתן להסיר רכב");
      }
    } catch (error) {
      console.error("Error removing vehicle:", error);
      showErrorToast("לא ניתן להסיר רכב");
    } finally {
      setIsAreYouSureOpen(false);
    }
  };

  const handleShowAreYouSureModal = (vehicle) => {
    setVehicleToRemove(vehicle);
    setIsAreYouSureOpen(true);
  };

  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setVehicleToRemove(null);
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex w-100 justify-content-end">
          {hasPermission("clients.organizers", "write") && (
            <Button className="table-btn" onClick={() => handleOpenModal()}>
              שיוך רכבים
            </Button>
          )}
        </div>
      </div>

      <div>
        {loading ? (
          <p>טוען...</p>
        ) : vehicles?.length > 0 ? (
          vehicles?.map((vehicle, index) => (
            <React.Fragment key={vehicle.id}>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p>
                    <strong className="font-weight-bold">שם הרכב:</strong>{" "}
                    {vehicle.vehicleName}
                  </p>
                  <p>
                    <strong className="font-weight-bold">תאריך שיוך:</strong>{" "}
                    {new Date(vehicle.assignedAt).toLocaleString("he-IL")}
                  </p>
                </div>
                {/* Optional Action Buttons */}
                <div className="d-flex gap-2">
                  {/* {hasPermission("clients.organizers", "edit") && (
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleOpenModal(vehicle)}
                    >
                      <BsPencilSquare /> עריכה
                    </Button>
                  )} */}
                  {hasPermission("clients.organizers", "delete") && (
                    <Button
                      variant="outline-danger"
                      onClick={() => handleShowAreYouSureModal(vehicle)}
                    >
                      <BsTrash /> מחיקה
                    </Button>
                  )}
                </div>
              </div>
              {index < vehicles.length - 1 && <hr />}
            </React.Fragment>
          ))
        ) : (
          <p>
            אין מנהלי התקנים עבור לקוח זה. לחץ על "הוסף מנהל התקן" כדי להוסיף
          </p>
        )}
      </div>

      {/* Modals */}
      <CreateDriverModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        fetchDrivers={fetchDrivers}
        driver={selectedDriver}
        setDriver={setSelectedDriver}
        client={client}
      />

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemoveVehicle}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך להסיר את הרכב ${vehicleToRemove?.vehicleName}?`}
      />
    </div>
  );
};

export default ClientDriverTab;
