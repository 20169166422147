import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { FaStar, FaRegStar } from "react-icons/fa";
import { fetchData } from "../../../../utils/fetchData";
import AreYouSureModal from "../../../../components/AreYouSureModal/AreYouSureModal";
import { CreateOrganizerModal } from "../../CreateOrganizerModal";
import { useToast } from "../../../../context/ToastContext";
import { usePermissions } from "../../../../context/usePermissions";

const ClientOrganizersTab = ({ client = {}, context }) => {
  const { hasPermission } = usePermissions();
  const { showSuccessToast, showErrorToast } = useToast();
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrganizer, setSelectedOrganizer] = useState(null);
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [organizerToRemove, setOrganizerToRemove] = useState(null);
  const [preferredOrganizerID, setPreferredOrganizerID] = useState(null);
  const [loadingPreferred, setLoadingPreferred] = useState(false);

  useEffect(() => {
    fetchOrganizers();
    fetchPreferredOrganizer();
  }, [client._id]);

  // Fetch the list of organizers for the client
  const fetchOrganizers = async () => {
    setLoading(true);
    try {
      const token = getToken();

      let request;
      if (context === "branch") {
        request = `branches/${client._id}/organizers`;
      } else {
        request = `clients/${client._id}/organizers`;
      }

      const organizersResponse = await fetchData({ request }, "GET", token);

      const activeOrganizersSet = await fetchActiveOrganizers(token);

      const organizersWithStatus = organizersResponse.organizers?.map(
        (organizer) => ({
          ...organizer,
          isActive: activeOrganizersSet.has(organizer._id),
        })
      );

      setOrganizers(organizersWithStatus);
    } catch (error) {
      console.error("Error fetching organizers:", error);
      showErrorToast("שגיאה בטעינת הסדרנים");
    } finally {
      setLoading(false);
    }
  };

  // תיקון לפונקציה fetchPreferredOrganizer
  const fetchPreferredOrganizer = async () => {
    try {
      if (!client || !client._id) {
        console.warn("לא ניתן לטעון סדרן מועדף: חסר מזהה לקוח");
        return;
      }

      const token = getToken();
      const entityType = context === "branch" ? "branch" : "client";
      const entityId = client._id;

      // שימוש בפרמטרים בצורה נכונה עם הפונקציה fetchData
      const response = await fetchData(
        { request: `organizers/get-preferred` }, // רק הנתיב, בלי api/v1/
        "GET",
        token,
        { entityId, entityType } // העברת הפרמטרים כאן
      );

      console.log("Preferred organizer response:", response);

      if (response && response.preferredOrganizer) {
        setPreferredOrganizerID(response.preferredOrganizer._id);
      } else {
        setPreferredOrganizerID(null);
      }
    } catch (error) {
      console.error("Error fetching preferred organizer:", error);
      showErrorToast("שגיאה בטעינת סדרן מועדף");
      setPreferredOrganizerID(null);
    }
  };

  // פונקציה לסימון סדרן כמועדף
  const handleTogglePreferred = async (organizerId) => {
    setLoadingPreferred(true);
    try {
      const token = getToken();
      const entityType = context === "branch" ? "branch" : "client";
      const entityId = client._id;

      if (preferredOrganizerID === organizerId) {
        // הסרת סדרן מועדף
        await fetchData(
          { request: `organizers/remove-preferred` },
          "DELETE",
          token,
          {}, // params עבור GET בלבד
          null,
          null,
          { entityId, entityType } // הנתונים עוברים כאן
        );
        setPreferredOrganizerID(null);
        showSuccessToast("הסדרן המועדף הוסר בהצלחה");
      } else {
        // הגדרת סדרן מועדף
        await fetchData(
          {
            request: `organizers/set-preferred/${organizerId}`,
            body: { entityId, entityType }, // העברת הנתונים כאן
          },
          "PUT",
          token
        );
        setPreferredOrganizerID(organizerId);
        showSuccessToast("הסדרן סומן כמועדף בהצלחה");
      }
    } catch (error) {
      console.error("Error updating preferred organizer:", error);
      showErrorToast("שגיאה בעדכון סדרן מועדף");
    } finally {
      setLoadingPreferred(false);
    }
  };

  // Fetch active organizers
  const fetchActiveOrganizers = async (token) => {
    try {
      const response = await fetchData(
        { request: `organizers/active-organizers` },
        "GET",
        token
      );

      return response && typeof response === "object"
        ? new Set(Object.keys(response))
        : new Set();
    } catch (error) {
      console.error("Error fetching active organizers:", error.message);
      return new Set();
    }
  };

  const getToken = () => {
    const userData = localStorage.getItem("userData");
    if (!userData) throw new Error("User is not authenticated");
    return JSON.parse(userData).token;
  };

  const handleOpenModal = (organizer = null) => {
    setSelectedOrganizer(organizer);
    setIsModalOpen(true);
  };

  const handleRemoveOrganizer = async () => {
    try {
      const token = getToken();

      await fetchData(
        { request: `organizers/${organizerToRemove._id}` },
        "DELETE",
        token
      );

      // אם הסדרן שנמחק היה הסדרן המועדף, עדכן את המצב המקומי
      if (organizerToRemove._id === preferredOrganizerID) {
        setPreferredOrganizerID(null);
      }

      setOrganizers((prev) =>
        prev.filter((organizer) => organizer._id !== organizerToRemove._id)
      );

      showSuccessToast("הסדרן הוסר בהצלחה");
    } catch (error) {
      console.error("Error removing organizer:", error);
      showErrorToast("לא ניתן להסיר סדרן");
    } finally {
      setIsAreYouSureOpen(false);
    }
  };

  const handleShowAreYouSureModal = (organizer) => {
    setOrganizerToRemove(organizer);
    setIsAreYouSureOpen(true);
  };

  const closeAreYouSureModal = () => {
    setIsAreYouSureOpen(false);
    setOrganizerToRemove(null);
  };

  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex w-100 justify-content-end">
          {hasPermission("clients.organizers", "write") && (
            <Button className="table-btn" onClick={() => handleOpenModal()}>
              הוספת סדרן
            </Button>
          )}
        </div>
      </div>

      {loading ? (
        <p>טוען...</p>
      ) : organizers?.length > 0 ? (
        organizers?.map((organizer, index) => (
          <div key={organizer._id}>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p>
                  <strong className="font-weight-bold">שם הסדרן:</strong>{" "}
                  {organizer.firstName} {organizer.lastName}
                  {organizer.isActive && (
                    <span
                      style={{
                        color: "green",
                        fontSize: "24px",
                        marginLeft: "10px",
                      }}
                    >
                      ●
                    </span>
                  )}
                </p>
                <p>
                  <strong className="font-weight-bold">מספר נייד:</strong>{" "}
                  {organizer.phoneNumber}
                </p>
                <p>
                  <strong className="font-weight-bold">פעילות אחרונה:</strong>{" "}
                  {organizer.lastActive
                    ? new Date(organizer.lastActive).toLocaleString("he-IL")
                    : "לא נרשמה פעילות"}
                </p>
              </div>
              <div className="d-flex gap-2">
                {hasPermission("clients.organizers", "edit") && (
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleOpenModal(organizer)}
                  >
                    <BsPencilSquare /> עריכה
                  </Button>
                )}
                {hasPermission("clients.organizers", "edit") && (
                  <Button
                    variant={
                      preferredOrganizerID === organizer._id
                        ? "warning"
                        : "outline-warning"
                    }
                    onClick={() => handleTogglePreferred(organizer._id)}
                    disabled={loadingPreferred}
                    title={
                      preferredOrganizerID === organizer._id
                        ? "הסר סדרן מועדף"
                        : "סמן כסדרן מועדף"
                    }
                  >
                    {preferredOrganizerID === organizer._id ? (
                      <FaStar />
                    ) : (
                      <FaRegStar />
                    )}
                  </Button>
                )}
                {hasPermission("clients.organizers", "delete") && (
                  <Button
                    variant="outline-danger"
                    onClick={() => handleShowAreYouSureModal(organizer)}
                  >
                    <BsTrash /> מחיקה
                  </Button>
                )}
              </div>
            </div>
            {index < organizers?.length - 1 && <hr />}
          </div>
        ))
      ) : (
        <p>לא קיימים סדרנים ללקוח זה. לחץ על "הוספת סדרן" להוספה.</p>
      )}

      {/* Modals */}
      <CreateOrganizerModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        fetchOrganizers={fetchOrganizers}
        organizer={selectedOrganizer}
        setOrganizer={setSelectedOrganizer}
        client={client}
      />

      <AreYouSureModal
        isAreYouSureOpen={isAreYouSureOpen}
        closeAreYouSureModal={closeAreYouSureModal}
        handleApprove={handleRemoveOrganizer}
        modalTitle="האם אתה בטוח?"
        modalMessage={`האם אתה בטוח שברצונך להסיר את הסדרן ${organizerToRemove?.firstName} ${organizerToRemove?.lastName}?`}
      />
    </div>
  );
};

export default ClientOrganizersTab;
