import React from "react";
import { MdSpaceDashboard, MdComputer } from "react-icons/md";
import { BsShield } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { FaBus } from "react-icons/fa";
import { PAGES } from "../../config/permissions"; // נוסיף את זה
import { FaIdCard , FaCar } from "react-icons/fa";

export const navItems = [
  {
    to: "/dashboard",
    icon: <MdSpaceDashboard />,
    label: "לוח הבקרה",
    permission: PAGES.DASHBOARD,
    end: true,
  },
  {
    to: "/dashboard/clients",
    icon: <MdComputer />,
    label: "לקוחות",
    permission: PAGES.CLIENTS,
  },
  {
    to: "/dashboard/trips",
    icon: <FaBus />,
    label: "נסיעות",
    permission: PAGES.TRIPS,
  },
  {
    to: "/dashboard/admins",
    label: "משתמשי מערכת",
    icon: <BsShield />,
    permission: PAGES.ADMINS,
  },
  {
    to: "/dashboard/settings",
    icon: <FiSettings />,
    label: "הגדרות",
    permission: PAGES.SETTINGS,
  },
  {
    to: "/dashboard/activities",
    icon: <FiSettings />,
    label: "פעילות",
    permission: PAGES.ACTIVITIES,
  },
  {
    to: "/dasboard/VEHICLE",
    label:"כרטסות",
    icon: <FaIdCard />,
    permission: PAGES.ACTIVITIES,
    className: "custom-card-class", 
    subItems: [
      {
        to: "/dashboard/vehicles",
        label: "רכבים",  // Vehicles
        icon: <FaCar />,
        permission: PAGES.ACTIVITIES,
        className: "custom-vehicle-class", 
      },
    ],
},

];
