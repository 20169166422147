// AdminLogin.jsx
import React, { useEffect, useState } from "react";
import "./login.scss";
import { Form, Button, Alert, InputGroup } from "react-bootstrap";
import Logo from "../../assets/icons/logo.svg";
import { fetchData } from "../../utils/fetchData";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const dataObj = {
      request: "admins/send-otp",
      email: inputEmail,
      password: inputPassword,
    };

    fetchData(dataObj, "POST")
      .then((response) => {
        setLoading(false);
        setShowErr(false);

        // העברת מספר הטלפון למסך ה-OTP
        navigate("/otp", {
          state: { phoneNumber: response.phoneNumber },
        });
      })
      .catch((error) => {
        setErrMessage(error.message);
        setShowErr(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      if (parsedUserData.token) {
        navigate("/dashboard");
      }
    }
  }, []);

  return (
    <div className="login-page">
      <div className="login__wrapper">
        <Form
          className="shadow p-4 m-4 bg-white rounded login__form"
          onSubmit={handleSubmit}
        >
          <img
            className="img-thumbnail mx-auto d-block mb-2 login__form__logo"
            src={Logo}
            alt="logo"
          />
          <div className="h4 mb-2 text-center">התחברות למערכת הניהול</div>
          {showErr && <Alert variant="danger">{errMessage}</Alert>}
          <Form.Group className="mb-2" controlId="email">
            <Form.Label>כתובת אימייל</Form.Label>
            <Form.Control
              type="email"
              value={inputEmail}
              placeholder="הכנס את כתובת האימייל שלך"
              onChange={(e) => setInputEmail(e.target.value)}
              required
              style={{ textAlign: "right" }}
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="password">
            <Form.Label>סיסמה</Form.Label>
            <InputGroup>
              <Form.Control
                type="password"
                value={inputPassword}
                placeholder="הכנס את הסיסמה שלך"
                onChange={(e) => setInputPassword(e.target.value)}
                required
                style={{ textAlign: "right" }}
              />
            </InputGroup>
          </Form.Group>

          {!loading ? (
            <Button
              className="w-100 mt-4 table-btn"
              variant="primary"
              type="submit"
            >
              המשך
            </Button>
          ) : (
            <Button
              className="w-100 table-btn mt-4"
              variant="primary"
              type="submit"
              disabled
            >
              רק רגע...
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

export default AdminLogin;
