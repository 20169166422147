import React, { useState, useEffect } from "react";
import { Nav, Modal, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { navItems } from "./navItems";
import { handleLogout } from "./authHelpers";
import Logo from "../../assets/icons/logo.svg";
import "./Sidemenu.scss";
import packageJson from "../../../package.json";
import { usePermissions } from "../../context/usePermissions";
import {  MdArrowDropDown } from "react-icons/md";

const SideMenu = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null); // State to track which dropdown is open
  const { hasPermission } = usePermissions();
  // Filter items based on permissions (you might already have this part)
  const filteredNavItems = navItems.filter(
    (item) => !item.permission || hasPermission(item.permission)
  );

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index); // Toggle dropdown open/close
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.name) {
      setUserName(userData.name);
    }
  }, []);

  return (
    <>
      <Nav className="d-none d-md-block bg-light sidebar">
        <div className="sidebar__logo">
          <img src={Logo} alt="Logo" className="sidebar__logo__img" />
          <div
            style={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            <span>
              היי, {userName}{" "}
              <span
                role="img"
                aria-label="wave"
                style={{
                  display: "inline-block",
                  animation: "wave-animation 1.5s infinite",
                  transformOrigin: "70% 70%",
                }}
              >
                👋
              </span>
            </span>
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            גרסה: {packageJson.version}
          </div>
        </div>
        <div className="sidebar__content">
          {filteredNavItems.map((item, index) => (
            <Nav.Item key={index} className={`nav-item ${item.className || ""}`}>
              {item.subItems ? (
                // This is a dropdown item
                <div>
                  <NavLink
                    onClick={() => toggleDropdown(index)}
                    className={({ isActive }) =>
                      isActive ? "nav-link drpdown" : "nav-link drpdown"
                    }
                  >
                    <div className="sidebar__item">
                      <div className="sidebar__item__icon">{item.icon}</div>
                      <span>{item.label}</span>
                      <MdArrowDropDown
                        style={{
                          transform: openDropdown === index ? "rotate(180deg)" : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  </NavLink>
                  {openDropdown === index && (
                    <div className="dropdown-menu">
                      {item.subItems.map((subItem, subIndex) => (
                       <Nav.Item key={subIndex} className={`nav-item ${subItem.className || ""}`} >
                          <NavLink  style={{height:"46px", marginLeft:"-20px"}}
                            to={subItem.to}
                            className={({ isActive }) =>
                              isActive ? "nav-link active" : "nav-link"
                            }
                          >
                            <div className="sidebar__item" >
                              <div className="sidebar__item__icon">{subItem.icon}</div>
                              <span>{subItem.label}</span>
                            </div>
                          </NavLink>
                        </Nav.Item>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                // Regular item (no dropdown)
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  end={item.end}
                >
                  <div className="sidebar__item">
                    <div className="sidebar__item__icon">{item.icon}</div>
                    <span>{item.label}</span>
                  </div>
                </NavLink>
              )}
            </Nav.Item>
          ))}
          <Nav.Item className="nav-item">
          <Nav.Link
            onClick={() => setShowLogoutModal(true)}
            className="nav-link"
          >
            התנתק
          </Nav.Link>
         </Nav.Item>
        </div>
        {/* <div className="sidebar__footer">
          <Nav.Item className="nav-item">
            <Nav.Link
              onClick={() => setShowLogoutModal(true)}
              className="nav-link"
            >
              התנתק
            </Nav.Link>
          </Nav.Item>
        </div> */}
      </Nav>

      {/* Logout Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>אישור התנתקות</Modal.Title>
        </Modal.Header>
        <Modal.Body>האם אתה בטוח שברצונך להתנתק?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            ביטול
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowLogoutModal(false);
              handleLogout(navigate, setLoading);
            }}
            disabled={loading}
          >
            התנתק
          </Button>
        </Modal.Footer>
      </Modal>

      <style>
        {`
          @keyframes wave-animation {
            0% { transform: rotate(0deg); }
            10% { transform: rotate(14deg); }
            20% { transform: rotate(-8deg); }
            30% { transform: rotate(14deg); }
            40% { transform: rotate(-4deg); }
            50% { transform: rotate(10deg); }
            60% { transform: rotate(0deg); }
            100% { transform: rotate(0deg); }
          }

          .dropdown-menu {
            padding-left: 20px;
            background-color: #f8f9fa;
            display: block;
            border-left: 3px solid #007bff; /* Optional styling */
          }


          .sidebar__item {
            display: flex;
            align-items: center;
          }

          .sidebar__item__icon {
            margin-right: 10px;
          }

          .sidebar__item span {
            flex-grow: 1;
          }
          

        `}
      </style>
    </>
  );
};

export default SideMenu;
