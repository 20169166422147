import React, { useState } from "react";
import { Navbar, Nav , NavDropdown  } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { navItems } from "../SideMenu/navItems";
import { handleLogout } from "../SideMenu/authHelpers";
import Logo from "../../assets/icons/logo.svg";
import { FaBars } from "react-icons/fa";
import "./mobileTopMenu.scss";

const MobileTopMenu = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);  // Manage dropdown open/close state
  
    const handleDropdownToggle = () => {
      setDropdownOpen(!dropdownOpen);  // Toggle the dropdown when the "Cards" item is clicked
    };
  
    const handleSubMenuClick = (path) => {
      navigate(path);  // Redirect to the submenu item path
      setDropdownOpen(false);  // Close the dropdown after selecting an option
    };
  
    return (
      <Navbar
        variant="dark"
        expand="lg"
        className="d-md-none navbar"
        sticky="top"
        expanded={expanded}
      >
        <Navbar.Brand as={Link} to="/dashboard">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "150px" }}
            className="img-thumbnail mx-auto d-block mb-2"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}>
          <FaBars size={24} className="custom-hamburger-icon" />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {navItems.map((item, index) => (
              <Nav.Item key={index}>
                {/* Check if the item has a subMenu */}
                {item.subMenu ? (
                  <NavDropdown
                    title={<> {item.label}</>}
                    id={`nav-dropdown-${index}`}
                    className="nav-link"
                    show={dropdownOpen}  // Controlled dropdown open state
                    onClick={handleDropdownToggle}  // Toggle dropdown on click
                  >
                    {/* Map submenu items */}
                    {item.subMenu.map((subItem, subIndex) => (
                      <NavDropdown.Item
                        key={subIndex}
                        onClick={() => handleSubMenuClick(subItem.to)}  // Navigate to the submenu item when clicked
                      >
                        {subItem.label}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    as={Link}
                    to={item.to}
                    onClick={handleClose}
                    className="nav-link"
                  >
                    {item.icon} {item.label}
                  </Nav.Link>
                )}
              </Nav.Item>
            ))}
            <Nav.Link
              onClick={() => {
                handleLogout(navigate, setLoading);
                handleClose();
              }}
              className="logout-link"
            >
              התנתק
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
};

export default MobileTopMenu;
