import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const carIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/3448/3448339.png", // אייקון חדש
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -35],
});

const IturanMapModal = ({ licensePlate, show, handleClose }) => {
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [extraData, setExtraData] = useState(null);

  const loginToIturan = async () => {
    const payload = {
      grant_type: "password",
      username: "KlikBusApi",
      password: "Api@2025@Klik",
      culture: "he-IL",
      temporary_login: false,
    };

    const response = await fetch("https://api.ituran.com/api/v1/oauth/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    return data?.access_token || null;
  };

  const fetchAddressFromCoords = async (lat, lon) => {
    try {
      const res = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&accept-language=he`
      );
      const data = await res.json();
      return data.display_name;
    } catch (err) {
      console.error("שגיאה בשליפת כתובת:", err);
      return null;
    }
  };

  const fetchVehicleInfoAndLocation = async () => {
    if (!licensePlate) return;
    setLoading(true);

    const token = await loginToIturan();
    if (!token) {
      setLoading(false);
      return;
    }

    try {
      const [infoRes, locationRes] = await Promise.all([
        fetch(`https://api.ituran.com/api/v2/vehicles/${licensePlate}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }),
        fetch(
          `https://api.ituran.com/api/v2/vehicles/${licensePlate}/location`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        ),
      ]);

      const infoData = await infoRes.json();
      const locationData = await locationRes.json();

      const point = locationData?.location?.point;
      if (point?.lat && point?.lon) {
        const address = await fetchAddressFromCoords(point.lat, point.lon);

        setLocation({ lat: point.lat, lng: point.lon });
        setExtraData({
          odometer: locationData.odometer,
          speed: locationData.speed,
          heading: locationData.heading,
          timestamp: locationData.timestamp,
          nickname: infoData.nickname,
          model_name: infoData.model_name,
          address: address || "כתובת לא זמינה",
        });
      } else {
        setLocation(null);
        setExtraData(null);
      }
    } catch (err) {
      console.error("❌ Error fetching vehicle data:", err);
      setLocation(null);
      setExtraData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchVehicleInfoAndLocation();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>מיקום הרכב איתורן</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: 500 }}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" />
          </div>
        ) : location ? (
          <>
            <MapContainer
              center={location}
              zoom={15}
              style={{ height: "300px", width: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={location} icon={carIcon}>
                <Popup>
                  <div>
                    <strong>🚗 רכב כאן</strong>
                    <br />
                    מהירות: {extraData?.speed ?? "N/A"} קמ״ש
                    <br />
                    כיוון: {extraData?.heading ?? "N/A"}°
                  </div>
                </Popup>
              </Marker>
            </MapContainer>

            <div className="mt-3">
              <h6 className="mb-2">📋 מידע נוסף:</h6>
              <ul className="list-unstyled mb-0">
                <li>
                  🚘 שם רכב:{" "}
                  {extraData?.nickname || extraData?.model_name || "לא ידוע"}
                </li>
                <li>📍 כתובת: {extraData?.address || "לא זמינה"}</li>
                <li>
                  📏 מד אוץ: {extraData?.odometer?.toLocaleString() ?? "N/A"}{" "}
                  ק״מ
                </li>
                <li>⚡ מהירות: {extraData?.speed ?? "N/A"} קמ״ש</li>
                <li>
                  ⏰ עדכון אחרון:{" "}
                  {new Date(extraData?.timestamp).toLocaleString("he-IL")}
                </li>
              </ul>
            </div>
          </>
        ) : (
          <div className="text-center text-muted">לא נמצאו נתוני מיקום</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          סגור
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IturanMapModal;
