import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button, Row, Col, Pagination, Form } from "react-bootstrap";
import { useLoading } from "../../../context/LoadingContext";
import { useToast } from "../../../context/ToastContext";
import { fetchData } from "../../../utils/fetchData";
import { getClientTypeText } from "../../../utils/supportFunctions";
import "../clients.scss";
import "./clientsTable.scss";
import Select from "react-select";

const ClientsTable = ({
  setIsOpen,
  clients,
  totalPages,
  initialState,
  updateUrlParams,
  cities = [],
  states = [],
  hasPermission,
}) => {
  const { showLoading, hideLoading } = useLoading();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("userData")).token;

  const [activeOrganizers, setActiveOrganizers] = useState({});
  const [activeThresholdMinutes, setActiveThresholdMinutes] = useState(null);
  const [searchTerm, setSearchTerm] = useState(initialState.searchTerm || "");
  const [selectedCity, setSelectedCity] = useState(
    initialState.selectedCity || ""
  );
  const [sortConfig, setSortConfig] = useState({
    field: initialState.sortField || "",
    order: initialState.sortOrder || "",
  });
  const [pendingSearchTerm, setPendingSearchTerm] = useState(searchTerm);
  const [pendingSelectedCity, setPendingSelectedCity] = useState(selectedCity);
  const [vehicleType, setVehicleType] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(
    initialState.selectedVehicleType || []
  );
  const [pendingselectedVehicleType, setPendingSelectedVehicleType] =
    useState(selectedVehicleType);
  const [currentPage, setCurrentPage] = useState(initialState.currentPage || 1);
  const [selectedState, setSelectedState] = useState(
    initialState.selectedState || ""
  );
  const [pendingSelectedState, setPendingSelectedState] =
    useState(selectedState);

  const minutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes >= 30 ? hours + 0.5 : hours;
  };

  useEffect(() => {
    const loadData = async () => {
      showLoading();
      try {
        await Promise.all([
          fetchActiveOrganizers(),
          fetchVehicle(),
          fetchActiveThresholdMinutes(),
        ]);
      } finally {
        hideLoading();
      }
    };

    loadData();
  }, []);

  const fetchActiveOrganizers = async () => {
    try {
      const response = await fetchData(
        { request: `organizers/active-organizers` },
        "GET",
        token
      );
      setActiveOrganizers(response);
    } catch (error) {
      console.error("Error fetching active organizers:", error);
      showErrorToast("שגיאה בטעינת סדרנים פעילים");
    }
  };

  const fetchVehicle = async () => {
    try {
      const response = await fetchData({ request: `vehicle` }, "GET", token);
      setVehicleType(
        response.vehicles.map((vehicle) => ({
          value: vehicle._id,
          label: vehicle.vehicleName,
        }))
      );
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      showErrorToast("שגיאה בטעינת סדרנים פעילים");
    }
  };

  const fetchActiveThresholdMinutes = async () => {
    try {
      const response = await fetchData(
        { request: `config/active-users-threshold` },
        "GET",
        token
      );
      setActiveThresholdMinutes(response.activeUsersDisplayThresholdMinutes);
    } catch (error) {
      console.error("Error fetching active users threshold:", error);
      showErrorToast("שגיאה בטעינת הגדרות זמן פעילות");
    }
  };

  // Handle column sorting
  const handleSort = (field) => {
    let order = "asc";
    if (sortConfig.field === field) {
      if (sortConfig.order === "asc") order = "desc";
      else if (sortConfig.order === "desc") {
        field = "";
        order = "";
      }
    }

    setSortConfig({ field, order });
    updateUrlParams(
      {
        sortField: field,
        sortOrder: order,
        page: 1,
      },
      true
    );
  };

  const handleSearch = (e) => {
    e?.preventDefault();
    setSearchTerm(pendingSearchTerm);
    setSelectedCity(pendingSelectedCity);
    setSelectedState(pendingSelectedState);
    setSelectedVehicleType(pendingselectedVehicleType);
  };

  useEffect(() => {}, [selectedVehicleType]);

  useEffect(() => {
    updateUrlParams(
      {
        search: searchTerm || null, // אם ריק, שלח null
        city: selectedCity || null, // אם ריק, שלח null
        state: selectedState || null, // אם ריק, שלח null
        vehicleType:
          selectedVehicleType.length > 0 ? selectedVehicleType.join(",") : null, // Convert array to string
        page: 1,
      },
      true
    );
  }, [searchTerm, selectedCity, selectedState, selectedVehicleType]);

  // Add this useEffect to handle URL updates when search states change
  useEffect(() => {
    if (
      searchTerm !== initialState.searchTerm ||
      selectedCity !== initialState.selectedCity ||
      selectedState !== initialState.selectedState ||
      selectedVehicleType !== initialState.selectedVehicleType
    ) {
      updateUrlParams(
        {
          search: searchTerm,
          city: selectedCity,
          state: selectedState,
          vehicleType:
            selectedVehicleType.length > 0
              ? selectedVehicleType.join(",")
              : null, // Convert array to string
          page: 1,
        },
        true
      );
    }
  }, [searchTerm, selectedCity, selectedState, selectedVehicleType]);

  const handleClearAllFilters = () => {
    setPendingSearchTerm("");
    setPendingSelectedCity("");
    setPendingSelectedState(""); // נוסף
    setSearchTerm("");
    setSelectedCity("");
    setSelectedState("");
    setPendingSelectedVehicleType([]);
    updateUrlParams(
      {
        search: null,
        city: null,
        state: null,
        vehicleType: null,
        page: 1,
      },
      true
    );
  };

  // Add sort indicators to column headers
  const getSortIcon = (field) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.order === "asc" ? " ▲" : " ▼";
  };

  const PaginationComponent = () => {
    const maxPages = 5;
    const itemsPerPage = 25;

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      updateUrlParams({ page: pageNumber }, true);
    };

    // First page
    const items = [
      <Pagination.First
        key="first"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      />,
      <Pagination.Prev
        key="prev"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />,
    ];

    // Calculate start and end pages
    const startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    const endPage = Math.min(totalPages, startPage + maxPages - 1);

    // Add page numbers
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    // Next and Last page
    items.push(
      <Pagination.Next
        key="next"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      />,
      <Pagination.Last
        key="last"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(totalPages)}
      />
    );

    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Pagination>{items}</Pagination>
          <div className="mx-3 d-flex justify-content-center align-items-center mt-2">
            <span className="text-muted">
              מציג {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(currentPage * itemsPerPage, totalPages * itemsPerPage)}{" "}
              מתוך {totalPages * itemsPerPage} תוצאות
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => (
    <div className="table-container">
      {clients.length > 0 ? (
        <div className="table-responsive">
          <Table responsive className="table-hover">
            <thead className="thead-light sticky-header">
              <tr>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("clientDriverCode")}
                >
                  קוד מפעיל {getSortIcon("clientDriverCode")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("name")}
                >
                  שם הלקוח {getSortIcon("name")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("city")}
                >
                  עיר {getSortIcon("city")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("state")}
                >
                  מחוז {getSortIcon("state")}
                </th>
                <th
                  className="font-weight-medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("vehicle")}
                >
                  סוגי רכב {getSortIcon("vehicle")}
                </th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client) => {
                const isActiveOrganizer = Object.values(activeOrganizers).some(
                  (organizer) =>
                    organizer.parentClient === client._id ||
                    organizer.branch === client._id
                );
                return (
                  <tr
                    key={client._id}
                    className="table-row hover-link"
                    onClick={() => navigate(`/dashboard/clients/${client._id}`)}
                  >
                    <td>{client.clientDriverCode || "-"}</td>
                    <td>
                      <div className="d-flex gap-2 align-items-center align-self-center">
                        <div
                          className={`d-flex gap-2 align-items-center align-self-center avatar ${
                            isActiveOrganizer ? "online" : "offline"
                          }`}
                        >
                          <div
                            style={{
                              paddingRight: "9px",
                              top: "9px",
                              position: "absolute",
                              fontSize: "13px",
                            }}
                          ></div>
                        </div>
                        <img
                          src={
                            client?.profileImage ||
                            "/uploads/default-profile.png"
                          }
                          alt="Profile"
                          className="profile-image"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            margin: 0,
                          }}
                        />
                        <div className="d-flex align-items-center">
                          {client.name}
                        </div>
                      </div>
                    </td>
                    <td>{client.city || "-"}</td>
                    <td>{client.state || "-"}</td>
                    <td>
                      {client.vehicleIds && client.vehicleIds.length > 0
                        ? client.vehicleIds
                            .map((vehicle) => vehicle.vehicleId?.vehicleName)
                            .filter((name) => name)
                            .join(", ") || "-"
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="no-data-message">אין נתונים להצגה</div>
      )}
    </div>
  );

  return (
    <Fragment>
      <div className="custom-table-container">
        <div className="table-header d-flex flex-column gap-4">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <span className="lead font-weight-bold">לקוחות רשומים</span>
              <div className="legend d-flex flex-column m-0 p-0">
                <div className="legend-item">
                  <span className="status-circle green-circle me-2"></span>
                  סדרנים שביצעו פעולה במערכת הסדרנים ב
                  {activeThresholdMinutes
                    ? activeThresholdMinutes <= 60
                      ? `${activeThresholdMinutes} דקות האחרונות`
                      : `${minutesToHours(
                          activeThresholdMinutes
                        )} שעות האחרונות`
                    : "..."}
                </div>
                <div className="legend-item">
                  <span className="status-circle red-circle me-2"></span>
                  סדרנים שלא ביצעו פעולה לאחרונה
                </div>
              </div>
            </div>

            {hasPermission("clients.addingClients", "write") && (
              <Button
                className="table-btn"
                variant="primary"
                onClick={() => setIsOpen(true)}
              >
                יצירת לקוח חדש
              </Button>
            )}
          </div>

          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <form onSubmit={handleSearch}>
                <div className="d-flex gap-2">
                  <input
                    type="text"
                    placeholder="חיפוש לפי שם, קוד מפעיל, מזהה או מייל..."
                    value={pendingSearchTerm}
                    onChange={(e) => setPendingSearchTerm(e.target.value)}
                    className="form-control"
                  />
                </div>
              </form>
            </Col>
            <Col md={3}>
              <Select
                isMulti
                options={vehicleType}
                value={vehicleType.filter((option) =>
                  pendingselectedVehicleType.includes(option.value)
                )}
                onChange={(selected) => {
                  setPendingSelectedVehicleType(
                    selected.map((item) => item.value)
                  );
                }}
                placeholder="בחר כלי רכב"
                classNamePrefix="react-select"
              />
            </Col>
            <Col md={2}>
              <Form.Select
                value={pendingSelectedCity}
                onChange={(e) => setPendingSelectedCity(e.target.value)}
                className="h-100"
              >
                <option value="">כל הערים</option>
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={2}>
              <Form.Select
                value={pendingSelectedState}
                onChange={(e) => setPendingSelectedState(e.target.value)}
                className="h-100"
              >
                <option value="">כל המחוזות</option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={1}>
              <Button type="submit" variant="primary" onClick={handleSearch}>
                חיפוש
              </Button>
            </Col>
            <Col xs={12} md={1} className="d-flex justify-content-center">
              <Button
                variant="link"
                className="p-0 text-decoration-underline border-0 shadow-none"
                onClick={handleClearAllFilters}
              >
                נקה הכל
              </Button>
            </Col>
          </Row>
        </div>

        {renderTable()}

        {clients.length > 0 && totalPages > 1 && <PaginationComponent />}
      </div>
    </Fragment>
  );
};

export default ClientsTable;
