import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { getClientTypeText } from "../../../../utils/supportFunctions";
import { BsPencilSquare } from "react-icons/bs";
import { useToast } from "../../../../context/ToastContext";
import { usePermissions } from "../../../../context/usePermissions";

export default function ClientDetailsInfoTab({
  client,
  editedClient,
  handleInputChange,
  handleCancelEdit,
  handleEditToggle,
  handleSaveChanges,
  context,
}) {
  const { hasPermission } = usePermissions();
  const { showErrorToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [useSameCode, setUseSameCode] = useState(
    !editedClient?.client_id ||
      editedClient?.client_id === editedClient?.clientDriverCode
  );

  const handleShowModal = () => {
    setIsSaving(false);
    setShowModal(true);
    handleEditToggle();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handleCancelEdit();
  };

  const handleSaveAndCloseModal = async () => {
    setIsSaving(true);
    try {
      if (useSameCode) {
        editedClient.client_id = editedClient.clientDriverCode;
      }

      await handleSaveChanges();
      setShowModal(false);
    } catch (e) {
      console.error("Save error:", e);
      const errorMessage =
        e.response?.data?.message || e.message || "שגיאה בשמירת השינויים";
      showErrorToast(errorMessage);
      setIsSaving(false);
    }
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    setUseSameCode(isChecked);

    if (isChecked) {
      handleInputChange({
        target: {
          name: "client_id",
          value: editedClient?.clientDriverCode || "",
        },
      });
    }
  };
  return (
    <div
      className="card-body"
      style={{
        height: "480px",
        maxHeight: "480px",
        overflowY: "auto",
      }}
    >
      <div className="row">
        <div className="col-md-10">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {context === "client" && (
              <>
                <div className="font-weight-bold">סוג לקוח:</div>
                <div>{getClientTypeText(client?.clientType)}</div>
              </>
            )}

            {context === "client" && (
              <>
                <div className="font-weight-bold">מזהה חברה:</div>
                <div>{client.llsNumber}</div>
              </>
            )}

            <div className="font-weight-bold">מייל הנה״ח:</div>
            <div>{client.billingEmail}</div>
            {!client.hasBranches && (
              <>
                <div className="font-weight-bold">קוד מפעיל:</div>
                <div>{client.clientDriverCode || "לא צויין"}</div>

                <div className="font-weight-bold">קוד לקוח:</div>
                <div>{client?.client_id || "לא צויין"}</div>

                <div className="font-weight-bold">עיר:</div>
                <div>{client.city || "לא צויין"}</div>
                <div className="font-weight-bold">מחוז:</div>
                <div>{client.state || "לא צויין"}</div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-2 justify-content-end d-flex align-items-start">
          {hasPermission("clients.details", "write") && (
            <Button variant="outline-secondary" onClick={handleShowModal}>
              <BsPencilSquare />
            </Button>
          )}
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {context === "client" ? "עריכת פרטי לקוח" : "עריכת פרטי סניף"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Existing form groups */}
            {context === "client" ? (
              <>
                <Form.Group className="mb-2">
                  <Form.Label>שם החברה:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={editedClient?.name || ""}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>סוג החברה:</Form.Label>
                  <Form.Select
                    name="clientType"
                    value={editedClient?.clientType || ""}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      בחר סוג חברה
                    </option>
                    <option value={1}>חברה פרטית</option>
                    <option value={2}>חברה ציבורית</option>
                    <option value={3}>עוסק מורשה</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>מזהה החברה:</Form.Label>
                  <Form.Control
                    type="text"
                    name="llsNumber"
                    value={editedClient?.llsNumber || ""}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </>
            ) : (
              <Form.Group className="mb-2">
                <Form.Label>שם הסניף:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editedClient?.name || ""}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            )}

            <Form.Group className="mb-2">
              <Form.Label>מייל הנה״ח:</Form.Label>
              <Form.Control
                type="email"
                name="billingEmail"
                value={editedClient?.billingEmail || ""}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {(context === "client" ? !client.hasBranches : true) && (
              <>
                {/* Toggle for same/different codes */}
                <Form.Group className="mb-2">
                  <Form.Label>קוד מפעיל:</Form.Label>
                  <Form.Control
                    type="text"
                    name="clientDriverCode"
                    value={editedClient?.clientDriverCode || ""}
                    onChange={(e) => {
                      handleInputChange(e);
                      if (useSameCode) {
                        handleInputChange({
                          target: {
                            name: "client_id",
                            value: e.target.value,
                          },
                        });
                      }
                    }}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="code-switch"
                    label="השתמש באותו קוד עבור נהג ולקוח"
                    checked={useSameCode}
                    onChange={handleToggleChange}
                  />
                </Form.Group>

                {!useSameCode && (
                  <Form.Group className="mb-2">
                    <Form.Label>קוד לקוח:</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_id"
                      value={editedClient?.client_id || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                )}

                <Form.Group className="mb-2">
                  <Form.Label>עיר:</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={editedClient?.city || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>מחוז:</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={editedClient?.state || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleCloseModal}
            disabled={isSaving}
          >
            ביטול
          </Button>
          <Button
            variant="success"
            onClick={handleSaveAndCloseModal}
            disabled={isSaving}
          >
            {isSaving ? <Spinner animation="border" size="sm" /> : "שמור"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
