import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TripsTable from "./tripsTable/tripsTable";
import { Container } from "react-bootstrap";
import { withPermissions } from "../../components/hoc/withPermissions";
import { PAGES } from "../../config/permissions";

const Trips = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // פונקציה לפרסור תאריך מה-URL
  const parseDateFromUrl = (paramName) => {
    const dateString = searchParams.get(paramName);
    return dateString ? new Date(dateString + "T00:00:00") : null;
  };

  // פונקציית מיפוי סטטוסים
  const mapDashboardStatusToTripsStatus = (status) => {
    const statusMap = {
      waiting: "open",
      taken: "inProgress",
      approved: "approved",
      arrived: "inProcess",
      completed: "completed",
      canceled: "cancelled",
    };
    return statusMap[status] || status;
  };

  // חישוב תאריכים לפי טווח
  const calculateDateRangeFromParams = (range) => {
    const now = new Date();
    let startDate = null;
    let endDate = null;

    switch (range) {
      case "tomorrow":
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1
        );
        endDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1,
          23,
          59,
          59
        );
        break;
      case "week":
        startDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - 7
        );
        endDate = new Date();
        break;
      case "today":
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        endDate = new Date();
        break;
      case "month":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date();
        break;
      case "nextWeek":
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        endDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 7
        );
        break;

      case "custom":
        // בדוק אם יש תאריכי התחלה וסיום מפורשים
        const customStartDate =
          searchParams.get("startDate") || searchParams.get("fromDate");
        const customEndDate =
          searchParams.get("endDate") || searchParams.get("toDate");

        if (customStartDate && customEndDate) {
          try {
            startDate = new Date(customStartDate);
            endDate = new Date(customEndDate);

            // וודא שהתאריכים תקינים
            if (isNaN(startDate) || isNaN(endDate)) {
              return { startDate: null, endDate: null };
            }
          } catch (error) {
            console.error("Invalid date format:", error);
            return { startDate: null, endDate: null };
          }
        } else {
          return { startDate: null, endDate: null };
        }
        break;
      case "fullMonth":
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;

      default:
        return { startDate: null, endDate: null };
    }

    return { startDate, endDate };
  };

  // מצב התחלתי דינמי
  const [initialState, setInitialState] = useState(() => {
    const range = searchParams.get("range");
    const hasAnyParams = Array.from(searchParams.entries()).length > 0;
    const defaultRange = hasAnyParams ? range : "today";
    const rangeCalculatedDates = calculateDateRangeFromParams(defaultRange);

    return {
      statusFilter: mapDashboardStatusToTripsStatus(
        searchParams.get("statusFilter") || searchParams.get("status") || "all"
      ),
      currentPage: parseInt(searchParams.get("page") || "1"),
      searchTerm: searchParams.get("search") || "",
      carTypeFilter: searchParams.get("carType") || "all",
      selectedTime: searchParams.get("time") || "",
      selectedCompany: searchParams.get("company") || "",

      startDate:
        parseDateFromUrl("startDate") ||
        parseDateFromUrl("fromDate") ||
        rangeCalculatedDates.startDate ||
        null,

      endDate:
        parseDateFromUrl("endDate") ||
        parseDateFromUrl("toDate") ||
        rangeCalculatedDates.endDate ||
        null,
    };
  });

  const updateUrlParams = (updates, triggerSearch = false) => {
    const newParams = Object.fromEntries(searchParams.entries());

    Object.keys(updates).forEach((key) => {
      const value = updates[key];

      if (value instanceof Date) {
        const dateKey =
          key === "startDate" ? "fromDate" : key === "endDate" ? "toDate" : key;

        const localDateString = value
          .toLocaleDateString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-");

        newParams[dateKey] = localDateString;
      } else if (value && value !== "all" && value !== "") {
        newParams[key] = value;
      } else {
        delete newParams[
          key === "startDate" ? "fromDate" : key === "endDate" ? "toDate" : key
        ];
      }
    });

    Object.keys(newParams).forEach(
      (key) => newParams[key] === "" && delete newParams[key]
    );

    if (triggerSearch) {
      setSearchParams(newParams, { replace: true });
    }

    return newParams;
  };

  return (
    <Container fluid className="p-4">
      <h2 className="font-weight-bold">נסיעות</h2>
      <TripsTable
        initialState={initialState}
        updateUrlParams={updateUrlParams}
      />
    </Container>
  );
};

export default withPermissions(Trips, PAGES.TRIPS);
