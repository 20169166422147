import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";

const ClientBranchesModals = ({
  show,
  onHide,
  onSave,
  initialData = { name: "", billingEmail: "", clientDriverCode: "" },
  title,
  saveButtonText,
}) => {
  const [branchData, setBranchData] = useState({ ...initialData });
  const [showValidation, setShowValidation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    billingEmail: "",
    clientDriverCode: "",
  });

  useEffect(() => {
    if (show) {
      setBranchData({ ...initialData });
      setShowValidation(false);
      setIsSaving(false);
      setErrors({ name: "", billingEmail: "", clientDriverCode: "" });
    }
  }, [
    show,
    initialData.name,
    initialData.billingEmail,
    initialData.clientDriverCode,
  ]);

  const validateName = (name) => {
    if (!name.trim()) return "שם סניף הוא שדה חובה";
    return "";
  };

  const validateEmail = (email) => {
    if (!email.trim()) return "כתובת מייל היא שדה חובה";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return "כתובת מייל לא תקינה";
    return "";
  };

  const validateDriverCode = (code) => {
    if (!code.trim()) return "קוד מפעיל הוא שדה חובה";
    const numberRegex = /^[0-9]+$/;
    if (!numberRegex.test(code)) return "קוד מפעיל חייב להכיל רק מספרים";
    return "";
  };

  const handleSave = async () => {
    const nameError = validateName(branchData.name);
    const emailError = validateEmail(branchData.billingEmail);
    const driverCodeError = validateDriverCode(branchData.clientDriverCode);

    setErrors({
      name: nameError,
      billingEmail: emailError,
      clientDriverCode: driverCodeError,
    });

    if (nameError || emailError || driverCodeError) {
      setShowValidation(true);
      return;
    }

    setIsSaving(true);
    try {
      await onSave(branchData);
    } catch (error) {
      console.error("Error saving branch:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    if (!isSaving) {
      onHide();
      setShowValidation(false);
    }
  };

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton={!isSaving}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>שם הסניף *</Form.Label>
            <Form.Control
              type="text"
              value={branchData.name}
              onChange={(e) => {
                setBranchData({ ...branchData, name: e.target.value });
                setErrors({ ...errors, name: validateName(e.target.value) });
              }}
              isInvalid={!!errors.name && showValidation}
              disabled={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>מייל לחשבוניות *</Form.Label>
            <Form.Control
              type="email"
              value={branchData.billingEmail}
              onChange={(e) => {
                setBranchData({ ...branchData, billingEmail: e.target.value });
                setErrors({
                  ...errors,
                  billingEmail: validateEmail(e.target.value),
                });
              }}
              isInvalid={!!errors.billingEmail && showValidation}
              disabled={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.billingEmail}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>קוד מפעיל *</Form.Label>
            <Form.Control
              type="text"
              value={branchData.clientDriverCode}
              onChange={(e) => {
                setBranchData({
                  ...branchData,
                  clientDriverCode: e.target.value,
                });
                setErrors({
                  ...errors,
                  clientDriverCode: validateDriverCode(e.target.value),
                });
              }}
              isInvalid={!!errors.clientDriverCode && showValidation}
              disabled={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.clientDriverCode}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel} disabled={isSaving}>
          ביטול
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isSaving}>
          {isSaving ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
          ) : (
            saveButtonText
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClientBranchesModals;
