import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import { useToast } from "../../context/ToastContext";
import Select from "react-select";

export const CreateDriverModal = ({
  isOpen,
  setIsOpen,
  fetchDrivers,
  driver,
  setDriver,
  client,
}) => {
  const { showSuccessToast, showErrorToast } = useToast();

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [vehicleError, setVehicleError] = useState(false);

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {
    console.log(selectedVehicle);
  }, [selectedVehicle]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    await addNewDriver();
  };

  const validateForm = () => {
    let valid = true;

    if (!selectedVehicle) {
      setVehicleError(true);
      valid = false;
    } else {
      setVehicleError(false);
    }
    return valid;
  };

  const addNewDriver = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }
      const requestData = client.parentClient
        ? {
            request: "vehicle/assign",
            vehicleIds: selectedVehicle,
            branchId: client._id,
            clientId: client.parentClient._id,
          }
        : {
            request: "vehicle/assign",
            vehicleIds: selectedVehicle,
            clientId: client._id,
          };

      const response = await fetchData(requestData, "POST", token);

      if (response.success) {
        showSuccessToast(response.message);
        setIsOpen(false);
        fetchDrivers();
        resetFormFields();
      } else {
        throw new Error(response.message || "שגיאה ביצירת רכב");
      }
    } catch (err) {
      console.error("Error adding new driver:", err.message);
      showErrorToast(
        err.response?.data?.message || err.message || "שגיאה ביצירת רכב"
      );
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    resetFormFields();
  };

  const resetFormFields = () => {
    setSelectedVehicle("");
    setVehicleError(false);
  };

  const fetchVehicles = async (page = 1, params = {}) => {
    try {
      const token = JSON.parse(localStorage.getItem("userData")).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const queryParams = {
        page,
        limit: 100,
        ...params,
      };

      const requestObject = { request: "vehicle" };
      const response = await fetchData(
        requestObject,
        "GET",
        token,
        queryParams
      );
      setVehicles(response.vehicles);
    } catch (err) {
      //setError(err.message);
    } finally {
      //hideLoading();
    }
  };

  const vehicleOptions = vehicles.map((vehicle) => ({
    value: vehicle._id,
    label: vehicle.vehicleName,
  }));

  return (
    <Modal show={isOpen} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>{driver ? "עריכת רכב" : "שיוך רכבים"}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="create_organizer_modal--wrapper">
            <Form.Group className="mb-3" controlId="vehicleSelect">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  בחר רכב
                </span>
              </div>
              <Select
                isMulti
                options={vehicleOptions}
                value={vehicleOptions.filter((option) =>
                  selectedVehicle.includes(option.value)
                )}
                onChange={(selected) =>
                  setSelectedVehicle(selected.map((option) => option.value))
                }
                placeholder="בחר רכב"
                classNamePrefix="react-select"
              />

              {vehicleError && (
                <span className="text-danger text-sm">שדה חובה</span>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            סגירה
          </Button>
          <Button variant="success" type="submit">
            המשך
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
